import Vue from 'vue'
import Vuex from 'vuex'
import store from '@/services/store'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    stores: [],
    selectedStore: null,
  },
  getters: {
    storeById: (state) => (id) => state.stores.find((s) => s.id === id),
    stores: (state) => state.stores,
    selectedStore: (state) => state.stores.find((s) => s.id === state.selectedStore),
  },
  mutations: {
    setStores (state, { stores }) {
      state.stores = stores
    },
  },
  actions: {
    async getStores ({ commit }, data) {
      const response = await store.getAll(data)
      commit('setStores', response)
      return response.stores
    },
  },
}

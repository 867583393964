import store from '../store'

export default (to, from, next) => {
  if (!store.getters['auth/isAuthenticated']) {
    return next({
      path: '/',
      query: { redirect: to.fullPath },
    })
  }

  next()
}

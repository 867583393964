import Vue from "vue";

Vue.filter("phone", function (value) {
  return `(${value.substring(
    0,
    2
  )}) ${value.substring(2, 7)}-${value.substring(7, 11)}`;
});

Vue.filter("percent", function (value, decimals = 2) {
  if (!value) return "0%";
  value = parseFloat(value) * 100;
  return `${value.toFixed(decimals)}%`;
});

Vue.filter("apartmentFormat", function ({ floor, number }, format = "FA") {
  const value = format
    .replace("T", floor - 1)
    .replace("F", floor)
    .replace("A", number);
  return value;
});

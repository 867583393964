// Imports
import Vue from 'vue'
import Router from 'vue-router'

// Middleware
import authenticate from '@/auth/authenticate'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      component: () => import('@/views/auth/Login.vue'),
    },
    {
      path: '/reset-password/:token',
      component: () => import('@/views/auth/ResetPassword.vue'),
    },
    {
      path: '/app',
      component: () => import('@/layouts/base/Index.vue'),
      beforeEnter: authenticate,
      children: [
        {
          path: '',
          name: 'Home',
          component: () => import('@/views/home/Home.vue'),
        },
        {
          path: 'init/:id',
          name: 'init-store',
          component: () => import('@/views/home/Init.vue'),
        },
        {
          path: ':id',
          name: 'store.index',
          component: () => import('@/views/home/Index.vue'),
        }
        // {
        //   path: '/components',
        //   name: 'Components',
        //   component: () => import('@/views/home/Index.vue'),
        // },
        // {
        //   path: '/sale-page-one',
        //   name: 'SalePageOne',
        //   component: () => import('@/views/pages/SalePageOne.vue'),
        // },
        // {
        //   path: '/sale-page-two',
        //   name: 'SalePageTwo',
        //   component: () => import('@/views/pages/SalePageTwo.vue'),
        // },
        // {
        //   path: '/all-vendors',
        //   name: 'AllVendors',
        //   component: () => import('@/views/vendor/AllVendors.vue'),
        // },
        // {
        //   path: '/vendor-store',
        //   name: 'VendorStore',
        //   component: () => import('@/views/vendor/VendorStore.vue'),
        // },
        // {
        //   path: '/search-product',
        //   name: 'SearchProduct',
        //   component: () => import('@/views/shop/SearchProduct.vue'),
        // },
        // {
        //   path: '/single-product',
        //   name: 'SingleProduct',
        //   component: () => import('@/views/shop/SingleProduct.vue'),
        // },
        // {
        //   path: '/cart',
        //   name: 'Cart',
        //   component: () => import('@/views/shop/Cart.vue'),
        // },
        // {
        //   path: '/checkout-alternative',
        //   name: 'CheckoutAlternative',
        //   component: () => import('@/views/shop/CheckoutAlternative.vue'),
        // },
        // {
        //   path: '/order-list',
        //   name: 'OrderList',
        //   component: () => import('@/views/Orders/OrderList.vue'),
        // },
        // {
        //   path: '/order-details',
        //   name: 'OrderDetails',
        //   component: () => import('@/views/Orders/OrderDetails.vue'),
        // },
        // {
        //   path: '/list-address',
        //   name: 'ListAddress',
        //   component: () => import('@/views/address/ListAddress.vue'),
        // },
        // {
        //   path: '/add-address',
        //   name: 'AddAddress',
        //   component: () => import('@/views/address/AddAddress.vue'),
        // },
        // {
        //   path: '/view-profile',
        //   name: 'ViewProfile',
        //   component: () => import('@/views/profile/ViewProfile.vue'),
        // },
        // {
        //   path: '/edit-profile',
        //   name: 'EditProfile',
        //   component: () => import('@/views/profile/EditProfile.vue'),
        // },
        // {
        //   path: '/all-tickets',
        //   name: 'AllTickets',
        //   component: () => import('@/views/AllTickets.vue'),
        // },
        // {
        //   path: '/ticket-details',
        //   name: 'TicketDetails',
        //   component: () => import('@/views/TicketDetails.vue'),
        // },
        // {
        //   path: '/wishlist',
        //   name: 'Wishlist',
        //   component: () => import('@/views/Wishlist.vue'),
        // },
        // {
        //   path: '/product-list',
        //   name: 'ProductList',
        //   component: () => import('@/views/ProductList.vue'),
        // },
        // {
        //   path: '/edit-product',
        //   name: 'EditProduct',
        //   component: () => import('@/views/EditProduct.vue'),
        // },
        // {
        //   path: '/profile',
        //   name: 'Profile',
        //   component: () => import('@/views/Profile.vue'),
        // },
        // {
        //   path: '/dashboard',
        //   name: 'Dashboard',
        //   component: () => import('@/views/Dashboard.vue'),
        // },

        // {
        //   path: '/test',
        //   name: 'Test',
        //   component: () => import('@/views/test'),
        // },
      ],
    },

    {
      path: '/landing',
      component: () => import('@/views/Landing.vue'),
    },
    {
      path: '/mobile-categories',
      component: () => import('@/views/MobileCategories.vue'),
    },
  ],
})

export default router

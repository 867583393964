import http from '../http-common'

const getAll = async (data) => {
  return await http
    .get('/store')
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error
    })
}
const init = async (storeId, data) => {
  return await http
    .post(`/store/${storeId}/init`, data)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error
    })
}

const createClient = async (storeId, data) => {
  return http.post(`/store/${storeId}/client`, data)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error
    })
}

const listClients = async (storeId) => {
  return http.get(`/store/${storeId}/client`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error
    })
}

const updateClient = async (storeId, clientId, data) => {
  return http.put(`/store/${storeId}/client/${clientId}`, data)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error
    })
}

const createUser = async (storeId, data) => {
  return http.post(`/store/${storeId}/user`, data)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error
    })
}

const listUser = async (storeId) => {
  return http.get(`/store/${storeId}/user`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error
    })
}

const updateUser = async (storeId, userId, data) => {
  return http.put(`/store/${storeId}/user/${userId}`, data)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error
    })
}

const deleteUsers = async (storeId, userId) => {
  return http.delete(`/store/${storeId}/user/${userId}`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error
    })
}




export default {
  getAll,
  init,
  client: {
    create: createClient,
    list: listClients,
    update: updateClient
  },
  user: {
    create: createUser,
    list: listUser,
    update: updateUser,
    delete: deleteUsers
  }
}

import Vue from 'vue'
import Vuex from 'vuex'
import auth from '@/services/auth'
import router from '@/router'
Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    token: null,
    user: null,
    permissionsLastUpdate: null,
    permissions: [],
    permissionsGroups: {},
  },
  getters: {
    isAuthenticated: (state) => state.token,
    user: (state) => state.user,
    userPermissions: (state) => state.user.UserStore,
    userFirstName: (state) => state.user.name.split(' ')[0],
    permissions: (state) => state.permissions,
    permissionsGroups: (state) => state.permissionsGroups,
    hasPermission: (state, getters, rootState) => (permission) => {
      const app = router.app
      const selectedStore = app.$route.params.id
      const orgPermissions = getters.userPermissions.find(
        (up) => up.storeId === selectedStore,
      )
      if (!orgPermissions) return false
      if (orgPermissions.isOwner) return true

      if (Array.isArray(permission)) {
        return permission.some((p) => verifyPermission(p, orgPermissions))
      } else {
        return verifyPermission(permission, orgPermissions)
      }
    },
  },
  mutations: {
    setLogin(state, { token, user }) {
      state.token = token
      state.user = user
    },
    setPermissions(state, { permissions, permissionsGroups }) {
      state.permissions = permissions
      state.permissionsGroups = permissionsGroups
      state.permissionsLastUpdate = new Date()
    },
  },
  actions: {
    async login({ commit }, data) {
      const response = await auth.login(data)
      commit('setLogin', response)
      return response
    },
    async renewToken({ commit, state, dispatch }) {
      try {
        const response = await auth.renewToken(state.token)
        commit('setLogin', response)
        return response
      } catch (e) {
        console.log('Renew Token error:', e)
        await dispatch('logout')
        return false
      }
    },
    async getPermissions({ commit, state }) {
      if (
        state.permissionsLastUpdate &&
        new Date(state.permissionsLastUpdate) > new Date() - 1000 * 60 * 60 * 12
      ) {
        return {
          permissions: state.permissions,
          permissionsGroups: state.permissionsGroups,
        }
      }
      const response = await auth.permissions()
      commit('setPermissions', response)
      return response
    },
    async logout({ commit }) {
      commit('setLogin', { token: null, user: null })
    },
  },
}

const verifyPermission = (permission, orgPermissions) =>
  (orgPermissions.permissions & permission) === permission

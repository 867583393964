import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from '@/plugins/vuetify'
import store from './store'
import './plugins'
import '@/assets/scss/_global.scss'
import "./plugins/vuetify-mask.js";
import "./plugins/filters"
// plugin svgrender
// import Vue plugin
import VueSvgInlinePlugin from 'vue-svg-inline-plugin'

// import polyfills for IE if you want to support it
import 'vue-svg-inline-plugin/src/polyfills'

// end:: svgrender

import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import VueApexCharts from 'vue-apexcharts'
Vue.config.productionTip = false
// use Vue plugin without options
Vue.use(VueSvgInlinePlugin)

// use Vue plugin with options
VueSvgInlinePlugin.install(Vue, {
  attributes: {
    data: ['src'],
    remove: ['alt'],
  },
})

Vue.component(VueSlickCarousel)
Vue.component('VueSlickCarousel', VueSlickCarousel)
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)
new Vue({
  router,
  vuetify,
  store,
  render: h => h(App),
}).$mount('#app')

import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth.js'
import storeModule from './modules/store.js'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        auth,
        store: storeModule,
    },
    plugins: [createPersistedState({ storage: window.localStorage })],
})

export default store
